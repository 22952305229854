import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { Upload, X, Pencil, Trash2, Heart, Tag, HelpCircle } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { formatDistanceToNow } from 'date-fns';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const Profile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const [error, setError] = React.useState('');
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [formData, setFormData] = React.useState({
    full_name: '',
    avatar_url: '',
  });
  const [editingItem, setEditingItem] = React.useState(null);
  const [itemImageFile, setItemImageFile] = React.useState<File | null>(null);
  const [itemImagePreview, setItemImagePreview] = React.useState<string | null>(null);
  const [deletingItem, setDeletingItem] = React.useState<string | null>(null);
  const [savedItems, setSavedItems] = React.useState([]);
  const [loadingSaved, setLoadingSaved] = React.useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [deletingAccount, setDeletingAccount] = React.useState(false);

  React.useEffect(() => {
    loadProfile();
    loadUserItems();
    loadSavedItems();
  }, []);

  React.useEffect(() => {
    if (formData.avatar_url && !imagePreview) {
      setImagePreview(formData.avatar_url);
    }
  }, [formData.avatar_url]);

  const loadProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/auth');
        return;
      }

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setProfile(data);
      setFormData({
        full_name: data.full_name || '',
        avatar_url: data.avatar_url || '',
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const loadUserItems = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('items')
        .select('*')
        .eq('seller_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setItems(data || []);
    } catch (err) {
      console.error('Error loading items:', err);
    }
  };

  const loadSavedItems = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('saved_items')
        .select(`
          id,
          item_id,
          created_at,
          items (
            id,
            title,
            price,
            image_url,
            location,
            status,
            created_at,
            profiles (
              id,
              full_name
            )
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSavedItems(data || []);
    } catch (err) {
      console.error('Error loading saved items:', err);
    } finally {
      setLoadingSaved(false);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(`Image size must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
        return;
      }
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleItemImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError(`Image size must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
        return;
      }
      setItemImageFile(file);
      setItemImagePreview(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setImageFile(null);
    setImagePreview(null);
    setFormData(prev => ({ ...prev, avatar_url: '' }));
    const input = document.getElementById('profile-image-upload') as HTMLInputElement;
    if (input) input.value = '';
  };

  const removeItemImage = () => {
    setItemImageFile(null);
    setItemImagePreview(null);
    const input = document.getElementById('item-image-upload') as HTMLInputElement;
    if (input) input.value = '';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUpdating(true);
    setError('');

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      let avatar_url = formData.avatar_url;

      if (imageFile) {
        if (formData.avatar_url) {
          const oldFilePath = formData.avatar_url.split('/').pop();
          if (oldFilePath) {
            await supabase.storage
              .from('avatars')
              .remove([`${user.id}/${oldFilePath}`]);
          }
        }

        const fileExt = imageFile.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('avatars')
          .upload(filePath, imageFile);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('avatars')
          .getPublicUrl(filePath);

        avatar_url = publicUrl;
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          full_name: formData.full_name,
          avatar_url: avatar_url,
        })
        .eq('id', user.id);

      if (updateError) throw updateError;
      
      setFormData(prev => ({ ...prev, avatar_url }));
      setProfile({ ...profile, full_name: formData.full_name, avatar_url });
      setError('Profile updated successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleEditItem = (item) => {
    setEditingItem({
      ...item,
      price: item.price.toString(),
    });
    setItemImagePreview(item.image_url);
  };

  const handleUpdateItem = async (e: React.FormEvent) => {
    e.preventDefault();
    setUpdating(true);
    setError('');

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      let image_url = editingItem.image_url;

      if (itemImageFile) {
        if (editingItem.image_url) {
          const oldFilePath = editingItem.image_url.split('/').pop();
          if (oldFilePath) {
            await supabase.storage
              .from('item-images')
              .remove([`${user.id}/${oldFilePath}`]);
          }
        }

        const fileExt = itemImageFile.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('item-images')
          .upload(filePath, itemImageFile);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('item-images')
          .getPublicUrl(filePath);

        image_url = publicUrl;
      }

      const { error: updateError } = await supabase
        .from('items')
        .update({
          title: editingItem.title,
          description: editingItem.description,
          price: parseFloat(editingItem.price),
          image_url: image_url,
          location: editingItem.location,
          status: editingItem.status,
        })
        .eq('id', editingItem.id);

      if (updateError) throw updateError;

      setItems(items.map(item => 
        item.id === editingItem.id 
          ? { ...editingItem, image_url, price: parseFloat(editingItem.price) }
          : item
      ));
      setEditingItem(null);
      setItemImageFile(null);
      setItemImagePreview(null);
      setError('Item updated successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    if (!window.confirm('Are you sure you want to delete this item? This action cannot be undone.')) {
      return;
    }

    setDeletingItem(itemId);
    try {
      const item = items.find(i => i.id === itemId);
      if (item?.image_url) {
        const oldFilePath = item.image_url.split('/').pop();
        if (oldFilePath) {
          await supabase.storage
            .from('item-images')
            .remove([`${profile.id}/${oldFilePath}`]);
        }
      }

      const { error } = await supabase
        .from('items')
        .delete()
        .eq('id', itemId);

      if (error) throw error;

      setItems(items.filter(item => item.id !== itemId));
      setError('Item deleted successfully!');
    } catch (err) {
      setError(err.message);
    } finally {
      setDeletingItem(null);
    }
  };

  const handleMarkAsSold = async (itemId: string) => {
    try {
      const { error } = await supabase
        .from('items')
        .update({ status: 'sold' })
        .eq('id', itemId);

      if (error) throw error;

      setItems(items.map(item =>
        item.id === itemId
          ? { ...item, status: 'sold' }
          : item
      ));
      setError('Item marked as sold!');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleItemChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditingItem(prev => ({ ...prev, [name]: value }));
  };

  const handleUnsaveItem = async (savedItemId: string) => {
    try {
      const { error } = await supabase
        .from('saved_items')
        .delete()
        .eq('id', savedItemId);

      if (error) throw error;
      setSavedItems(savedItems.filter(item => item.id !== savedItemId));
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteAccount = async () => {
    setDeletingAccount(true);
    try {
      const { error } = await supabase.auth.admin.deleteUser(profile.id);
      if (error) throw error;
      await supabase.auth.signOut();
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      setDeletingAccount(false);
      setShowDeleteConfirm(false);
    }
  };

  if (loading) return <div className="text-center py-8">Loading...</div>;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center mb-6">Your Profile</h2>

        {error && (
          <div className={`${
            error.includes('success')
              ? 'bg-green-100 border-green-400 text-green-700'
              : 'bg-red-100 border-red-400 text-red-700'
          } px-4 py-3 rounded mb-4 border`}>
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Full Name
            </label>
            <input
              type="text"
              name="full_name"
              value={formData.full_name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
            />
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Profile Picture
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              {imagePreview ? (
                <div className="relative">
                  <img
                    src={imagePreview}
                    alt="Profile Preview"
                    className="max-h-64 rounded"
                  />
                  <button
                    type="button"
                    onClick={removeImage}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="profile-image-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none"
                    >
                      <span>Upload a file</span>
                      <input
                        id="profile-image-upload"
                        name="profile-image-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="sr-only"
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 5MB
                  </p>
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            disabled={updating}
            className="w-full bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            {updating ? 'Updating...' : 'Update Profile'}
          </button>
        </form>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">Your Listed Items</h2>
        <div className="space-y-6">
          {items.map(item => (
            <div key={item.id} className="border rounded-lg p-4">
              {editingItem?.id === item.id ? (
                <form onSubmit={handleUpdateItem} className="space-y-4">
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={editingItem.title}
                      onChange={handleItemChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Description
                    </label>
                    <textarea
                      name="description"
                      value={editingItem.description}
                      onChange={handleItemChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                      rows={3}
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Price ($)
                    </label>
                    <input
                      type="number"
                      name="price"
                      value={editingItem.price}
                      onChange={handleItemChange}
                      step="0.01"
                      min="0"
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={editingItem.location}
                      onChange={handleItemChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Status
                    </label>
                    <select
                      name="status"
                      value={editingItem.status}
                      onChange={handleItemChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                    >
                      <option value="available">Available</option>
                      <option value="pending">Pending</option>
                      <option value="sold">Sold</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Item Image
                    </label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      {itemImagePreview ? (
                        <div className="relative">
                          <img
                            src={itemImagePreview}
                            alt="Item Preview"
                            className="max-h-64 rounded"
                          />
                          <button
                            type="button"
                            onClick={removeItemImage}
                            className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
                          >
                            <X className="h-4 w-4" />
                          </button>
                        </div>
                      ) : (
                        <div className="space-y-1 text-center">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="item-image-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none"
                            >
                              <span>Upload a file</span>
                              <input
                                id="item-image-upload"
                                name="item-image-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleItemImageChange}
                                className="sr-only"
                              />
                            </label>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 5MB
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => {
                        setEditingItem(null);
                        setItemImageFile(null);
                        setItemImagePreview(null);
                      }}
                      className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={updating}
                      className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                      {updating ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </form>
              ) : (
                <div className="flex items-start space-x-4">
                  <img
                    src={item.image_url}
                    alt={item.title}
                    className="w-32 h-32 object-cover rounded"
                  />
                  <div className="flex-1">
                    <div className="flex items-start justify-between">
                      <div>
                        <div className="flex items-center space-x-2">
                          <h3 className="text-lg font-semibold">{item.title}</h3>
                          {item.status === 'sold' && (
                            <span className="px-2 py-1 bg-red-100 text-red-800 text-xs font-medium rounded">
                              Sold
                            </span>
                          )}
                          {item.status === 'pending' && (
                            <span className="px-2 py-1 bg-yellow-100 text-yellow-800 text-xs font-medium rounded">
                              Pending
                            </span>
                          )}
                        </div>
                        <p className="text-gray-600">{item.description}</p>
                        <p className="text-primary-600 font-bold mt-1">
                          ${item.price}
                        </p>
                        <p className="text-gray-500 text-sm mt-1">
                          Location: {item.location}
                        </p>
                      </div>
                      <div className="flex space-x-2">
                        {item.status === 'available' && (
                          <button
                            onClick={() => handleMarkAsSold(item.id)}
                            className="p-2 text-green-600 hover:text-green-800"
                            title="Mark as Sold"
                          >
                            <Tag className="h-5 w-5" />
                          </button>
                        )}
                        <button
                          onClick={() => handleEditItem(item)}
                          className="p-2 text-gray-600 hover:text-primary-600"
                          title="Edit Item"
                        >
                          <Pencil className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteItem(item.id)}
                          disabled={deletingItem === item.id}
                          className="p-2 text-red-600 hover:text-red-800"
                          title="Delete Item"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {items.length === 0 && (
            <p className="text-center text-gray-500">
              You haven't listed any items yet.
            </p>
          )}
        </div>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">Saved Items</h2>
        {loadingSaved ? (
          <div className="text-center py-4">Loading saved items...</div>
        ) : savedItems.length === 0 ? (
          <div className="text-center py-4 text-gray-500">
            <Heart className="h-12 w-12 mx-auto mb-2" />
            <p>No saved items yet</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {savedItems.map(({ id, items: item }) => (
              <div key={id} className="border rounded-lg overflow-hidden">
                <img
                  src={item.image_url}
                  alt={item.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <div className="flex justify-between items-start">
                    <h3 className="text-lg font-semibold">{item.title}</h3>
                    <button
                      onClick={() => handleUnsaveItem(id)}
                      className="text-red-500 hover:text-red-600"
                      title="Remove from saved"
                    >
                      <Heart className="h-5 w-5 fill-current" />
                    </button>
                  </div>
                  <p className="text-primary-600 font-bold mt-1">
                    ${item.price}
                  </p>
                  <p className="text-sm text-gray-500 mt-1">
                    {item.location}
                  </p>
                  <p className="text-sm text-gray-500">
                    Listed by {item.profiles.full_name}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    Saved {formatDistanceToNow(new Date(item.created_at))} ago
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Support and Privacy Section */}
      <div className="bg-white p-8 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <HelpCircle className="h-8 w-8 text-primary-600 mr-2" />
            <h2 className="text-2xl font-bold">Help & Support</h2>
          </div>
          <Link 
            to="/privacy"
            className="text-primary-600 hover:text-primary-700 transition-colors"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="space-y-4">
          <p className="text-gray-600">
            Need assistance? Our support team is here to help you with any questions or concerns.
          </p>
          <a 
            href="mailto:support@xchangestuff.com"
            className="inline-flex items-center space-x-2 text-lg text-primary-600 hover:text-primary-700 transition-colors"
          >
            <span>support@xchangestuff.com</span>
          </a>
        </div>
      </div>

      {/* Account Deletion Section */}
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Delete Account</h2>
        <p className="text-gray-600 mb-4">
          Permanently delete your account and all associated data. This action cannot be undone.
        </p>
        <button
          onClick={() => setShowDeleteConfirm(true)}
          className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Delete Account
        </button>

        {showDeleteConfirm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
              <h3 className="text-xl font-bold text-red-600 mb-4">Confirm Account Deletion</h3>
              <p className="text-gray-600 mb-4">
                This will permanently delete:
              </p>
              <ul className="list-disc ml-6 mb-4 text-gray-600">
                <li>Your profile information</li>
                <li>All your listings</li>
                <li>Messages and ratings</li>
                <li>Saved items</li>
              </ul>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteAccount}
                  disabled={deletingAccount}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  {deletingAccount ? 'Deleting...' : 'Confirm Delete'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;