import React from 'react';
import { supabase } from '../lib/supabase';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { Search, MapPin, Tag } from 'lucide-react';

const Home = () => {
  const [items, setItems] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [userLocation, setUserLocation] = React.useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  React.useEffect(() => {
    loadItems();
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  };

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  };

  const loadItems = async () => {
    const { data, error } = await supabase
      .from('items')
      .select(`
        *,
        seller:seller_id (
          id,
          full_name
        ),
        item_images(url, order)
      `)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error loading items:', error);
    } else {
      setItems(data || []);
    }
  };

  const filteredItems = items.filter(item =>
    (item.title.toLowerCase().includes(search.toLowerCase()) ||
    item.description.toLowerCase().includes(search.toLowerCase()) ||
    item.location?.toLowerCase().includes(search.toLowerCase()))
  ).sort((a, b) => {
    if (userLocation && a.latitude && a.longitude && b.latitude && b.longitude) {
      const distanceA = calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        a.latitude,
        a.longitude
      );
      const distanceB = calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        b.latitude,
        b.longitude
      );
      return distanceA - distanceB;
    }
    return 0;
  });

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
      <div className="mb-6">
        <div className="relative max-w-xl mx-auto">
          <input
            type="text"
            placeholder="Search items..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-4 py-3 pl-10 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 sm:gap-4">
        {filteredItems.map((item) => (
          <Link 
            key={item.id} 
            to={`/items/${item.id}`}
            className="group bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-200"
          >
            <div className="aspect-square relative overflow-hidden bg-gray-100">
              <img
                src={item.image_url}
                alt={item.title}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
                loading="lazy"
              />
              {item.status === 'sold' && (
                <div className="absolute top-1 right-1 sm:top-2 sm:right-2">
                  <span className="bg-red-500 text-white px-1.5 py-0.5 sm:px-2 sm:py-1 rounded-full text-xs sm:text-sm font-medium">
                    Sold
                  </span>
                </div>
              )}
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-2 sm:p-4">
                <p className="text-white font-bold text-sm sm:text-lg">${item.price}</p>
              </div>
            </div>
            <div className="p-2 sm:p-4">
              <h3 className="text-gray-900 font-medium text-sm sm:text-base line-clamp-2">
                {item.title}
              </h3>
              {item.location && (
                <div className="flex items-center mt-1 sm:mt-2 text-gray-600">
                  <MapPin className="h-3 w-3 sm:h-4 sm:w-4 mr-1 flex-shrink-0" />
                  <span className="text-xs sm:text-sm truncate">{item.location}</span>
                </div>
              )}
              <div className="mt-1 sm:mt-2 flex items-center justify-between text-xs sm:text-sm text-gray-500">
                <span className="truncate">{formatDistanceToNow(new Date(item.created_at))} ago</span>
                <span className="truncate ml-2">{item.seller.full_name}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {filteredItems.length === 0 && (
        <div className="text-center py-12">
          <Tag className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No items found</h3>
          <p className="text-gray-500">Try adjusting your search terms</p>
        </div>
      )}
    </div>
  );
};

export default Home;