import React from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { v4 as uuidv4 } from 'uuid';
import { Upload, X, MapPin, AlertCircle, Camera, Image, Crosshair } from 'lucide-react';
import { checkProhibitedItems } from '../lib/prohibited-items';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_IMAGES = 5;

const ListItem = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [imageFiles, setImageFiles] = React.useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = React.useState<string[]>([]);
  const [showPhotoOptions, setShowPhotoOptions] = React.useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = React.useState(false);
  const [formData, setFormData] = React.useState({
    title: '',
    description: '',
    price: '',
    location: '',
    latitude: null as number | null,
    longitude: null as number | null,
  });

  const cameraInputRef = React.useRef<HTMLInputElement>(null);
  const galleryInputRef = React.useRef<HTMLInputElement>(null);

  const getZipCodeFromCoords = async (lat: number, lon: number) => {
    try {
      const response = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`
      );
      const data = await response.json();
      return data.postcode || '';
    } catch (error) {
      console.error('Error getting zip code:', error);
      return '';
    }
  };

  const handleGetLocation = async () => {
    setIsLoadingLocation(true);
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      const zipCode = await getZipCodeFromCoords(latitude, longitude);

      setFormData(prev => ({
        ...prev,
        location: zipCode,
        latitude,
        longitude,
      }));
    } catch (error) {
      console.error('Error getting location:', error);
      setError('Could not get your location. Please enter it manually.');
    } finally {
      setIsLoadingLocation(false);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const validFiles = files.filter(file => {
      if (file.size > MAX_FILE_SIZE) {
        setError(`Each image must be less than ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
        return false;
      }
      return true;
    });

    if (imageFiles.length + validFiles.length > MAX_IMAGES) {
      setError(`Maximum ${MAX_IMAGES} images allowed`);
      return;
    }

    setImageFiles(prev => [...prev, ...validFiles]);
    
    // Create preview URLs
    const newPreviews = validFiles.map(file => URL.createObjectURL(file));
    setImagePreviews(prev => [...prev, ...newPreviews]);
    setShowPhotoOptions(false);
  };

  const removeImage = (index: number) => {
    setImageFiles(prev => prev.filter((_, i) => i !== index));
    setImagePreviews(prev => {
      // Revoke the URL to prevent memory leaks
      URL.revokeObjectURL(prev[index]);
      return prev.filter((_, i) => i !== index);
    });
  };

  const handleCameraCapture = () => {
    if (cameraInputRef.current) {
      cameraInputRef.current.click();
    }
  };

  const handleGalleryUpload = () => {
    if (galleryInputRef.current) {
      galleryInputRef.current.click();
    }
  };

  const validateListing = () => {
    if (imageFiles.length === 0) {
      setError('Please add at least one image');
      return false;
    }
    const prohibitedError = checkProhibitedItems(formData.title, formData.description);
    if (prohibitedError) {
      setError(prohibitedError);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!validateListing()) {
      setLoading(false);
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Please sign in to list an item');

      // First, create the item
      const { data: item, error: itemError } = await supabase
        .from('items')
        .insert([
          {
            ...formData,
            price: parseFloat(formData.price),
            seller_id: user.id,
            image_url: '', // Will be updated with the first image URL
          },
        ])
        .select()
        .single();

      if (itemError) throw itemError;

      // Upload all images and create item_images entries
      const imageUploads = imageFiles.map(async (file, index) => {
        const fileExt = file.name.split('.').pop();
        const fileName = `${uuidv4()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('item-images')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('item-images')
          .getPublicUrl(filePath);

        // Create item_images entry
        await supabase
          .from('item_images')
          .insert([
            {
              item_id: item.id,
              url: publicUrl,
              order: index,
            },
          ]);

        // Update the item's main image_url with the first image
        if (index === 0) {
          await supabase
            .from('items')
            .update({ image_url: publicUrl })
            .eq('id', item.id);
        }
      });

      await Promise.all(imageUploads);
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (error && (name === 'title' || name === 'description')) {
      setError('');
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center mb-6">List an Item</h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        <div className="mb-4 p-4 bg-blue-50 rounded-lg">
          <h3 className="font-semibold text-blue-900 mb-2">Listing Guidelines</h3>
          <p className="text-blue-800 text-sm">
            To maintain a safe marketplace, the following items are prohibited:
          </p>
          <ul className="list-disc list-inside text-sm text-blue-800 mt-2">
            <li>Weapons and ammunition</li>
            <li>Drugs and controlled substances</li>
            <li>Counterfeit or stolen items</li>
            <li>Any other illegal items or services</li>
          </ul>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Images (up to 5)
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="relative aspect-square">
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ))}
              {imagePreviews.length < 5 && (
                <div 
                  onClick={() => setShowPhotoOptions(true)}
                  className="aspect-square border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-primary-500 transition-colors"
                >
                  <Upload className="h-8 w-8 text-gray-400 mb-2" />
                  <span className="text-sm text-gray-500">Add Photos</span>
                </div>
              )}
            </div>
            <p className="text-xs text-gray-500">
              Add up to 5 photos (max 10MB each)
            </p>

            {/* Hidden file inputs */}
            <input
              ref={cameraInputRef}
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleImageChange}
              className="hidden"
            />
            <input
              ref={galleryInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
              multiple
            />

            {/* Photo options modal */}
            {showPhotoOptions && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-end justify-center z-50">
                <div className="bg-white w-full max-w-md rounded-t-xl p-4">
                  <div className="space-y-4">
                    <button
                      type="button"
                      onClick={handleCameraCapture}
                      className="w-full flex items-center justify-center space-x-2 p-4 text-lg font-medium text-gray-700 hover:bg-gray-50 rounded-lg"
                    >
                      <Camera className="h-6 w-6" />
                      <span>Take Photo</span>
                    </button>
                    <button
                      type="button"
                      onClick={handleGalleryUpload}
                      className="w-full flex items-center justify-center space-x-2 p-4 text-lg font-medium text-gray-700 hover:bg-gray-50 rounded-lg"
                    >
                      <Image className="h-6 w-6" />
                      <span>Choose from Gallery</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowPhotoOptions(false)}
                      className="w-full p-4 text-lg font-medium text-red-600 hover:bg-red-50 rounded-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
              rows={4}
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Location
            </label>
            <div className="flex space-x-2">
              <div className="relative flex-1">
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Enter ZIP code"
                  className="w-full px-3 py-2 pl-10 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                  required
                  pattern="[0-9]{5}"
                  title="Please enter a valid 5-digit ZIP code"
                />
                <MapPin className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              <button
                type="button"
                onClick={handleGetLocation}
                disabled={isLoadingLocation}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-500 flex items-center space-x-2"
              >
                <Crosshair className="h-5 w-5" />
                <span className="hidden sm:inline">
                  {isLoadingLocation ? 'Getting location...' : 'Use my location'}
                </span>
              </button>
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Enter your ZIP code or use your current location
            </p>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Price ($)
            </label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              min="0"
              step="0.01"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
              required
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full bg-primary-600 text-white py-2 px-4 rounded hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            {loading ? 'Listing...' : 'List Item'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ListItem;