import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { ArrowLeftRight, PlusCircle, User, MessageSquare, Wrench, Menu, X, LogOut } from 'lucide-react';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  React.useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menu = document.getElementById('mobile-menu');
      if (menu && !menu.contains(event.target as Node) && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMenuOpen]);

  React.useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate('/auth');
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-primary-600 text-white shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center space-x-3 group">
            <div className="relative bg-white rounded-lg p-1.5">
              <ArrowLeftRight 
                className="h-6 w-6 text-primary-600 transform rotate-45 group-hover:scale-110 transition-transform duration-300" 
              />
            </div>
            <span className="text-2xl font-bold">Xchange</span>
          </Link>

          <div className="hidden lg:flex items-center space-x-4">
            {user ? (
              <>
                <Link to="/list-item" className="flex items-center space-x-1 hover:text-primary-200">
                  <PlusCircle className="h-5 w-5" />
                  <span>List Item</span>
                </Link>
                <Link to="/list-service" className="flex items-center space-x-1 hover:text-primary-200">
                  <PlusCircle className="h-5 w-5" />
                  <span>List Service</span>
                </Link>
                <Link to="/services" className="flex items-center space-x-1 hover:text-primary-200">
                  <Wrench className="h-5 w-5" />
                  <span>Services</span>
                </Link>
                <Link to="/messages" className="flex items-center space-x-1 hover:text-primary-200">
                  <MessageSquare className="h-5 w-5" />
                  <span>Messages</span>
                </Link>
                <div className="flex items-center space-x-4 pl-2 border-l border-primary-500">
                  <Link to="/profile" className="hover:text-primary-200">
                    <User className="h-5 w-5" />
                  </Link>
                  <button
                    onClick={handleSignOut}
                    className="flex items-center space-x-1 hover:text-primary-200"
                  >
                    <LogOut className="h-5 w-5" />
                  </button>
                </div>
              </>
            ) : (
              <div className="flex items-center space-x-2">
                <Link
                  to="/auth"
                  className={`px-4 py-2 rounded-md transition-colors ${
                    location.pathname === '/auth' && !location.search.includes('signup')
                      ? 'bg-white text-primary-600'
                      : 'hover:bg-primary-500'
                  }`}
                >
                  Sign In
                </Link>
                <Link
                  to="/auth?signup=true"
                  className={`px-4 py-2 rounded-md transition-colors ${
                    location.pathname === '/auth' && location.search.includes('signup')
                      ? 'bg-white text-primary-600'
                      : 'bg-primary-500 hover:bg-primary-400'
                  }`}
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>

          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="lg:hidden p-2 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-white"
            aria-expanded={isMenuOpen}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        <div 
          id="mobile-menu"
          className={`lg:hidden fixed top-0 right-0 bottom-0 w-64 bg-primary-600 shadow-xl transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          } z-50`}
        >
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between p-4 border-b border-primary-500">
              <span className="text-lg font-semibold">Menu</span>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="p-2 rounded-md hover:bg-primary-700 focus:outline-none"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            
            <div className="flex-1 overflow-y-auto">
              {user ? (
                <div className="p-4 space-y-2">
                  <Link
                    to="/list-item"
                    className="flex items-center space-x-3 p-3 rounded-lg hover:bg-primary-700"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <PlusCircle className="h-5 w-5" />
                    <span>List Item</span>
                  </Link>
                  <Link
                    to="/list-service"
                    className="flex items-center space-x-3 p-3 rounded-lg hover:bg-primary-700"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <PlusCircle className="h-5 w-5" />
                    <span>List Service</span>
                  </Link>
                  <Link
                    to="/services"
                    className="flex items-center space-x-3 p-3 rounded-lg hover:bg-primary-700"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <Wrench className="h-5 w-5" />
                    <span>Services</span>
                  </Link>
                  <Link
                    to="/messages"
                    className="flex items-center space-x-3 p-3 rounded-lg hover:bg-primary-700"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <MessageSquare className="h-5 w-5" />
                    <span>Messages</span>
                  </Link>
                  <Link
                    to="/profile"
                    className="flex items-center space-x-3 p-3 rounded-lg hover:bg-primary-700"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <User className="h-5 w-5" />
                    <span>Profile</span>
                  </Link>
                  <div className="pt-2 mt-2 border-t border-primary-500">
                    <button
                      onClick={handleSignOut}
                      className="flex items-center space-x-3 p-3 rounded-lg hover:bg-primary-700 w-full text-left text-red-200"
                    >
                      <LogOut className="h-5 w-5" />
                      <span>Sign Out</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="p-4 space-y-2">
                  <Link
                    to="/auth"
                    className={`block w-full p-3 rounded-lg text-center ${
                      location.pathname === '/auth' && !location.search.includes('signup')
                        ? 'bg-white text-primary-600'
                        : 'hover:bg-primary-700'
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Sign In
                  </Link>
                  <Link
                    to="/auth?signup=true"
                    className={`block w-full p-3 rounded-lg text-center ${
                      location.pathname === '/auth' && location.search.includes('signup')
                        ? 'bg-white text-primary-600'
                        : 'bg-primary-500 hover:bg-primary-400'
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Sign Up
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 lg:hidden"
            onClick={() => setIsMenuOpen(false)}
          />
        )}
      </div>
    </nav>
  );
};

export default Navbar;