// List of prohibited items and keywords
export const PROHIBITED_ITEMS = {
  weapons: [
    'gun', 'rifle', 'pistol', 'firearm', 'ammunition', 'ammo', 'weapon',
    'knife', 'sword', 'explosive', 'grenade', 'bomb'
  ],
  drugs: [
    'drug', 'cocaine', 'heroin', 'meth', 'marijuana', 'cannabis', 'weed',
    'pills', 'steroids', 'narcotic'
  ],
  other: [
    'counterfeit', 'fake id', 'stolen', 'illegal', 'prescription',
    'controlled substance'
  ]
};

export const checkProhibitedItems = (title: string, description: string): string | null => {
  const text = `${title} ${description}`.toLowerCase();
  
  for (const [category, terms] of Object.entries(PROHIBITED_ITEMS)) {
    for (const term of terms) {
      if (text.includes(term)) {
        return `This listing may contain prohibited items (${category}). Please review our guidelines.`;
      }
    }
  }
  
  return null;
}