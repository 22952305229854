import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { formatDistanceToNow } from 'date-fns';
import { MessageSquare, MapPin, LogIn, Heart, ChevronLeft, ChevronRight } from 'lucide-react';
import AffiliateBanner from '../components/AffiliateBanner';

const ItemDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const [user, setUser] = React.useState(null);
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [sendingMessage, setSendingMessage] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isSaved, setIsSaved] = React.useState(false);
  const [savingItem, setSavingItem] = React.useState(false);

  React.useEffect(() => {
    loadItem();
    checkUser();
  }, [id]);

  React.useEffect(() => {
    if (user && item) {
      checkIfSaved();
    }
  }, [user, item]);

  const checkUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  };

  const checkIfSaved = async () => {
    try {
      const { data, error } = await supabase
        .from('saved_items')
        .select('id')
        .eq('user_id', user.id)
        .eq('item_id', id)
        .maybeSingle();

      if (error) throw error;
      setIsSaved(!!data);
    } catch (err) {
      console.error('Error checking saved status:', err);
    }
  };

  const loadItem = async () => {
    try {
      const { data, error } = await supabase
        .from('items')
        .select(`
          *,
          seller:seller_id (
            id,
            full_name
          ),
          item_images (
            id,
            url,
            "order"
          )
        `)
        .eq('id', id)
        .single();

      if (error) throw error;
      setItem(data);
      
      // Sort images by order and include the main image_url
      const allImages = [
        { id: 'main', url: data.image_url, order: -1 },
        ...(data.item_images || []).sort((a, b) => a.order - b.order)
      ].filter(img => img.url);
      
      setImages(allImages);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveItem = async () => {
    if (!user) {
      navigate('/auth');
      return;
    }

    setSavingItem(true);
    try {
      if (isSaved) {
        const { error } = await supabase
          .from('saved_items')
          .delete()
          .eq('user_id', user.id)
          .eq('item_id', id);

        if (error) throw error;
        setIsSaved(false);
      } else {
        const { error } = await supabase
          .from('saved_items')
          .insert([
            {
              user_id: user.id,
              item_id: id,
            },
          ]);

        if (error) throw error;
        setIsSaved(true);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSavingItem(false);
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      navigate('/auth');
      return;
    }

    setSendingMessage(true);
    setError('');

    try {
      const { error: messageError } = await supabase
        .from('messages')
        .insert([
          {
            item_id: id,
            sender_id: user.id,
            receiver_id: item.seller_id,
            content: message,
          },
        ]);

      if (messageError) throw messageError;
      setMessage('');
    } catch (err) {
      setError(err.message);
    } finally {
      setSendingMessage(false);
    }
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === images.length - 1 ? 0 : prev + 1
    );
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? images.length - 1 : prev - 1
    );
  };

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (!item) return <div className="text-center py-8">Item not found</div>;

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="md:flex">
          <div className="md:w-1/2">
            {images.length > 0 && (
              <div className="relative">
                <img
                  src={images[currentImageIndex].url}
                  alt={`${item.title} - Image ${currentImageIndex + 1}`}
                  className="w-full h-96 object-cover"
                />
                {images.length > 1 && (
                  <>
                    <button
                      onClick={previousImage}
                      className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                    >
                      <ChevronLeft className="h-6 w-6" />
                    </button>
                    <button
                      onClick={nextImage}
                      className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                    >
                      <ChevronRight className="h-6 w-6" />
                    </button>
                    <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex space-x-2">
                      {images.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentImageIndex(index)}
                          className={`w-2 h-2 rounded-full transition-colors ${
                            currentImageIndex === index
                              ? 'bg-white'
                              : 'bg-white/50 hover:bg-white/75'
                          }`}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="p-8 md:w-1/2">
            <div className="flex justify-between items-start">
              <h1 className="text-3xl font-bold text-gray-900">{item.title}</h1>
              <button
                onClick={handleSaveItem}
                disabled={savingItem}
                className={`p-2 rounded-full ${
                  isSaved 
                    ? 'text-red-500 hover:text-red-600' 
                    : 'text-gray-400 hover:text-gray-500'
                }`}
                title={isSaved ? 'Remove from saved' : 'Save item'}
              >
                <Heart className={`h-6 w-6 ${isSaved ? 'fill-current' : ''}`} />
              </button>
            </div>
            <p className="text-2xl font-bold text-primary-600 mt-2">
              ${item.price}
            </p>
            {item.location && (
              <div className="flex items-center mt-4 text-gray-600">
                <MapPin className="h-5 w-5 mr-2" />
                <span>{item.location}</span>
              </div>
            )}
            <div className="mt-4">
              <p className="text-gray-600">{item.description}</p>
            </div>
            <div className="mt-6">
              <p className="text-sm font-medium text-gray-900">
                Listed by {item.seller.full_name}
              </p>
              <p className="text-sm text-gray-500">
                {formatDistanceToNow(new Date(item.created_at))} ago
              </p>
            </div>

            {error && (
              <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                {error}
              </div>
            )}

            {user ? (
              <form onSubmit={handleSendMessage} className="mt-6">
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Send a message to the seller..."
                    className="flex-1 px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-primary-500"
                    required
                  />
                  <button
                    type="submit"
                    disabled={sendingMessage}
                    className="bg-primary-600 text-white p-2 rounded hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  >
                    <MessageSquare className="h-5 w-5" />
                  </button>
                </div>
              </form>
            ) : (
              <div className="mt-6">
                <button
                  onClick={() => navigate('/auth')}
                  className="w-full flex items-center justify-center space-x-2 bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  <LogIn className="h-5 w-5" />
                  <span>Sign in to message seller</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add affiliate banner after item details */}
      <AffiliateBanner category={item.category} />
    </div>
  );
};

export default ItemDetails;