import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto py-8">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Collection and Use</h2>
          <p className="mb-4">We collect the following information:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Email address for authentication</li>
            <li>Profile information (name, avatar)</li>
            <li>Location data (when provided)</li>
            <li>Messages between users</li>
            <li>Item and service listings</li>
            <li>Device information for security</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Storage and Security</h2>
          <p className="mb-4">Your data is securely stored using Supabase's infrastructure. We implement industry-standard security measures to protect your information.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
          <p className="mb-4">You have the right to:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Access your personal data</li>
            <li>Correct inaccurate data</li>
            <li>Request data deletion</li>
            <li>Export your data</li>
            <li>Opt-out of tracking</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Deletion</h2>
          <p className="mb-4">You can request data deletion by:</p>
          <ul className="list-disc ml-6 mb-4">
            <li>Using the account deletion option in your profile settings</li>
            <li>Contacting our support team</li>
          </ul>
          <p>Data will be permanently deleted within 30 days of your request.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p className="mb-4">For privacy-related inquiries:</p>
          <p>Email: privacy@xchangestuff.com</p>
          <p>Address: [Your Business Address]</p>
        </section>

        <div className="mt-8 pt-8 border-t">
          <p className="text-sm text-gray-600">Last updated: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;