import React from 'react';
import { ExternalLink, X } from 'lucide-react';

interface AffiliateProduct {
  title: string;
  description: string;
  imageUrl: string;
  affiliateUrl: string;
  price: string;
}

interface AffiliateBannerProps {
  category?: string;
}

const AffiliateBanner: React.FC<AffiliateBannerProps> = ({ category = 'general' }) => {
  const [isVisible, setIsVisible] = React.useState(true);
  const [products, setProducts] = React.useState<AffiliateProduct[]>([]);

  React.useEffect(() => {
    // In a real implementation, this would fetch from your affiliate product API
    // or CMS based on the category
    const mockProducts: AffiliateProduct[] = [
      {
        title: "Professional Moving Boxes Kit",
        description: "Complete moving kit with boxes, tape, and bubble wrap",
        imageUrl: "https://images.unsplash.com/photo-1603861609805-29b5fda4a585?w=200",
        affiliateUrl: "https://amazon.com/moving-kit",
        price: "$49.99"
      },
      {
        title: "Premium Tool Set",
        description: "108-piece household tool kit with carrying case",
        imageUrl: "https://images.unsplash.com/photo-1530124566582-a618bc2615dc?w=200",
        affiliateUrl: "https://amazon.com/tool-set",
        price: "$89.99"
      }
    ];

    // Filter or modify products based on category
    setProducts(mockProducts);
  }, [category]);

  if (!isVisible || products.length === 0) return null;

  return (
    <div className="bg-gradient-to-r from-primary-50 to-white rounded-lg shadow-sm my-6 relative overflow-hidden">
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-2 right-2 p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-white/50"
        title="Close recommendations"
      >
        <X className="h-4 w-4" />
      </button>
      
      <div className="p-4">
        <h3 className="text-sm font-medium text-primary-900 mb-2">Recommended Products</h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {products.map((product, index) => (
            <a
              key={index}
              href={product.affiliateUrl}
              target="_blank"
              rel="noopener noreferrer sponsored"
              className="flex items-center space-x-3 bg-white p-3 rounded-lg hover:shadow-md transition-shadow group"
            >
              <img
                src={product.imageUrl}
                alt={product.title}
                className="w-20 h-20 object-cover rounded"
              />
              <div className="flex-1 min-w-0">
                <div className="flex items-center">
                  <h4 className="text-sm font-medium text-gray-900 truncate group-hover:text-primary-600">
                    {product.title}
                  </h4>
                  <ExternalLink className="h-3 w-3 ml-1 text-gray-400 group-hover:text-primary-600" />
                </div>
                <p className="text-xs text-gray-500 line-clamp-2 mt-1">
                  {product.description}
                </p>
                <p className="text-sm font-medium text-primary-600 mt-1">
                  {product.price}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AffiliateBanner;