import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Auth from './pages/Auth';
import ListItem from './pages/ListItem';
import ListService from './pages/ListService';
import ItemDetails from './pages/ItemDetails';
import Profile from './pages/Profile';
import Messages from './pages/Messages';
import Services from './pages/Services';
import Privacy from './pages/Privacy';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className="container mx-auto px-4 py-4 sm:py-8 max-w-7xl">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/list-item" element={<ListItem />} />
            <Route path="/list-service" element={<ListService />} />
            <Route path="/items/:id" element={<ItemDetails />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/services" element={<Services />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App